import React from "react"
import { graphql } from "gatsby"
import RichTextRenderer from "./richTextRenderer"

const TextComponent = ({ data }) => <RichTextRenderer data={data.textBody} key={data.id}/>

export const query = graphql`
  fragment TextComponentFragment on ContentfulTextComponent {
    id
    header
    __typename
    node_locale
    name
    textBody {
      raw
      references {
        ... on ContentfulAsset {
          contentful_id
          title
          description
          gatsbyImageData(layout: CONSTRAINED, quality: 80, formats: [WEBP, AUTO], placeholder: BLURRED)
          __typename
        }
      }
    }
  }
`

export default TextComponent