import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { INLINES, BLOCKS, MARKS } from "@contentful/rich-text-types";



const options = {
    renderMark: {
        [MARKS.BOLD]: (text) => <b className="font-bold">{text}</b>,
        [MARKS.ITALIC]: (text) => <i className="font-italic">{text}</i>,
        [MARKS.UNDERLINE]: (text) => <u className="underline">{text}</u>,
        [MARKS.CODE]: (text) => (
            <code className="font-mono px-1 py-2 mx-1 bg-gray-100 rounded text-sm">
                {text}
            </code>
        ),
    },
    renderNode: {

        [BLOCKS.EMBEDDED_ASSET]: (node) => {
            // render the EMBEDDED_ASSET as you need
            console.log(node)
            const { gatsbyImageData, description } = node.data.target
            return (
                <div className="align-center">
                    <GatsbyImage
                        image={getImage(gatsbyImageData)}
                        alt={description}
                        className="mb-10"
                    />
                </div>
            );
        },
    },
};

const RichTextRenderer = ({ data }) => {
    return <div>{renderRichText(data, options)}</div>
}

export default RichTextRenderer