import React from "react"
import { graphql,Link } from "gatsby"
import { Box, Card, CardActions, CardContent, CardMedia,Button } from "@mui/material"
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import { GatsbyImage } from "gatsby-plugin-image"
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { isValidURL } from '../utilities/is-link'

const coverDesktop = {
  width: '100%',
  height: '350px',
  '@media only screen and (maxWidth: 600px)': {
    display: 'flex',
    width: '100%',
    height: '75vW',
    maxHeight: '100vw'
  }
}
const buttonSx = {
  whiteSpace: 'nowrap',
  margin: "0;",
  padding: "0;",
  textDecoration: "none;",
  maxWidth: "100%;",
  textTransform: 'none',
  borderRadius: "20px;",
  overflow: "hidden;",
  fontWeight: 'bold',
  fontSize: '1rem',

}

const dontShowIfDesktop = {
  display: {
      sm: 'none!important;',
      md: 'none!important;',
      lg: 'none!important;',
      xl: 'none!important;',
  },
  flex: '1 0 50%',
  width: '100%',
  height: 'auto'

}

const imgWrapper = {
  flex: '1 0 50%',
  display: 'flex',
  width: '100%',
  height: 'auto'
}

const imgWrapperRight = {
  flex: '1 0 50%',
  width: '100%',
  height: 'auto',
  display: { xs: 'none', sm: 'flex', md: 'flex', xl: 'flex' }
}
const dontShow = {
  display: 'none!important;',
}

const FWHeroComponent = ({ data,imgPosition='left', btnBgColor = "#82BF37" }) => {
  const {actions} = data
  return (
    <Card variant='outlined' sx={theme => ({
      display: 'flex',
      flex: '1 0 100%',
      flexDirection: 'row',
      height: 'auto',
      alignItems: 'center',
      maxHeight: '600px',
      mt:5,
      border:`1px solid ${btnBgColor}`,
      padding:'50px',
      backgroundColor: '#FCFCFC' || theme.palette.primary.main,
      borderRadius:'5px',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        maxHeight: '100%',
        mt:2
      }
    })} square elevation={0}>
      <CardMedia sx={(imgPosition === 'left') ? imgWrapper : dontShowIfDesktop} >
        <GatsbyImage image={data.image.gatsbyImageData} alt={data?.image?.title} style={coverDesktop} />
      </CardMedia>
      <Box sx={theme => ({
        flex: '1 0 50%',
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: (imgPosition === 'left') ? '5%' :'0px',
        boxSizing: 'border-box;',
        [theme.breakpoints.down('sm')]: {
          paddingLeft: 0,
          paddingBottom: theme.spacing(2),
          flex: '1 1 100%',
        }
      })} >
        <CardContent >
          <ReactMarkdown plugins={[gfm]} rehypePlugins={[rehypeRaw]} children={data?.text?.text} />
        </CardContent >
        {actions?.length > 0 && <CardActions>
                    <Box sx={theme => ({
                        display: 'flex',
                        width: '100%',
                        gap: '2%',
                        flexDirection:'row',
                        [theme.breakpoints.down('sm')]: {
                            width: '100%',
                            flexDirection:'column',
                            rowGap:'0.5rem'
                        }
                    })}>
                        {actions.map((action, index) => {
                            if (!isValidURL(action.to)) {
                                return <Button sx={{
                                    ...buttonSx,
                                    bgcolor: btnBgColor,
                                    '&:hover': {
                                        filter: 'brightness(0.8)!important;',
                                        bgcolor: btnBgColor,
                                    }
                                }} fullWidth component={Link} to={action.to} key={index} endIcon={<ArrowCircleRightIcon
                                />}

                                >{action.label}</Button>
                            }
                            return <Button sx={{
                                ...buttonSx,
                                bgcolor: btnBgColor, '&:hover': {
                                    filter: 'brightness(0.8)!important;',
                                    bgcolor: btnBgColor,
                                }
                            }} fullWidth component="a" href={action.to} key={index} endIcon={<ArrowCircleRightIcon
                            />}

                            >{action.label}</Button>

                        })}
                    </Box>
                </CardActions>}
      </Box>
      <CardMedia sx={
        (imgPosition === 'right') ? imgWrapperRight : dontShow}>
        <GatsbyImage image={data.image.gatsbyImageData} alt={data?.image?.title} style={coverDesktop} />
      </CardMedia>
    </Card>

  )


}







export const query = graphql`
fragment FWHeroComponentFragment on ContentfulFullWidthHero {
  id
  __typename
  name
  image {
    gatsbyImageData(width: 1200, quality: 100, jpegProgressive: true)
    description
    title
  }
  text {
    text
  }
  actions: butonlar {
    label: buttonText
    name
    id
    to: url
  }
}

`

export default FWHeroComponent