import * as React from "react"
import { graphql } from 'gatsby'
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import get from 'lodash/get'
import TextComponent from "../../components/text-component"
import FWHeroComponent from "../../components/fw-hero-component"
import FWBannerComponent from "../../components/fw-banner-component"
import {britColors} from "../../config/colors"

class DefaultTemplate extends React.Component {
    render() {
        const page = get(this.props.data, 'contentfulPageComponent')
        let fwCounter = 0
        const pageBody = page.pageBody.map((item) => {
          if (item.__typename === 'ContentfulFullWidthHero') {
            fwCounter = fwCounter + 1
          }
            switch (item.__typename) {
                case "ContentfulTextComponent": return <TextComponent data={item} key={item.id} />
                case "ContentfulFullWidthHero": return <FWHeroComponent data={item} key={item.id} imgPosition = {fwCounter %2 === 0 ? 'right': 'left' } btnBgColor={britColors[fwCounter%britColors.length]}/>
                case "ContentfulImageComponent": return <FWBannerComponent data={item} key={item.id} />
                default: return null
            }
        
        })
        return (
            <Layout location={this.props.location} pageTitle={page.pageHeader} pageContext={this.props.pageContext}>
                <Seo title={page.pageHeader} />
                <div>
                    <h1>{page.pageHeader}</h1>
                    {pageBody}
                </div>
            </Layout>
        )
    }
}

export default DefaultTemplate

export const query = graphql`
query PageQuery($pageId: String)
  {
    contentfulPageComponent(id: {eq: $pageId},node_locale: {eq: "tr-TR"}) {
      id
      name
      pageDescription
      pageHeader
      node_locale
      pageBody {
        ...TextComponentFragment
        ...FWHeroComponentFragment
        ...FWBannerComponentFragment
      }
    }
  }
`

