import React from "react";
import { graphql, Link } from "gatsby"
import { Box, Typography, Button } from "@mui/material";
import { GatsbyImage } from "gatsby-plugin-image";
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

const boxSx = {
    width: '100%',
    position: 'relative',
}
const textWrapperSx = {
    position: 'absolute',
    display: { sm: 'none', md: 'flex' },
    flexDirection: 'column',
    zIndex: 1,
    width: { sm: '35vW' }
}
const textWrapperSxLeft = {
    left: '5%',
    top: '50%',
    transform: 'translateY(-50%);'

}
const textWrapperSxRight = {
    right: '5%',
    top: '50%',
    transform: 'translateY(-50%);'
}
const textWrapperSxCenter = {
    left: '50%',
    top: '50%;',
    transform: 'translate(-50%,-50%);'
}

const textBoxStyle = {
    left: textWrapperSxLeft,
    right: textWrapperSxRight,
    center: textWrapperSxCenter

}
const headerSx = {
    fontFamily: ['Helvetica Neue', 'sans-serif'],
    fontWeight: 600,
    fontSize: { sm: '1.3rem', md: '1.5rem', lg: '2rem' }
}
const subTitleSx = {
    fontSize: { sm: '1rem', md: '1.3rem' }
}
const bodyTextSx = {
    fontSize: { sm: '0.8rem', md: '1rem' }
}
const darkSx = {
    color: 'white'
}
const controlsSx = {
    display: 'flex',
    width: { sm: '100%', md: '50%' },
    gap: '2%',
}

const buttonSx = {
    whiteSpace: 'nowrap',
    margin: "0;",
    padding: "0;",
    textDecoration: "none;",
    maxWidth: "100%;",
    textTransform: 'none',
    borderRadius: "20px;",
    overflow: "hidden;",
    fontWeight: 'bold',
    fontSize: '1rem',
}

const buttonSxDark = {
    whiteSpace: 'nowrap',
    borderRadius: 0,
    color: 'black',
    backgroundColor: 'white',
    "&:hover, &:focus": {
        color: 'white',
        backgroundColor: 'black',
    },

}
const BannerActions = ({ actions }) => {
    const btnBg = "#82BF37"
    return (
        <Box sx={controlsSx}>
            {actions.map((action, index) => {
                return (
                    <Button sx={{
                        ...buttonSx,
                        bgcolor: btnBg, '&:hover': {
                            filter: 'brightness(0.8)!important;',
                            bgcolor: btnBg,
                        }
                    }} fullWidth component={Link} to={action.to} key={index} endIcon={<ArrowCircleRightIcon
                    />}

                    >{action.label}</Button>
                )

            })}
        </Box>
    )
}

const FWBannerComponent = ({ data }) => {
    const { actions } = data
    const displayText = data.hasOwnProperty('title') & data.hasOwnProperty('subtitle')
    const textPosition = data.textPosition || 'center'
    return (
        <Box sx={boxSx}>
            <GatsbyImage image={data.image.gatsbyImageData} alt={data.image.description} />
            {displayText === 1 &&
                <Box sx={{
                    ...textWrapperSx, ...textBoxStyle[textPosition]
                }}>
                    <Typography sx={headerSx} noWrap>{data.title}</Typography>
                    <Typography sx={subTitleSx} gutterBottom>{data.subtitle}</Typography>
                    <Typography sx={bodyTextSx} gutterBottom>{data.bodytext}</Typography>
                    {actions?.length > 0 && <BannerActions actions={actions} />}
                </Box>
            }
            </Box>

    )

}



export default FWBannerComponent

export const query = graphql`
  fragment FWBannerComponentFragment on ContentfulImageComponent {
    id
    __typename
    name
    node_locale
    subtitle
    title
    textPosition
    bodytext
    actions {
        label: buttonText
        id
        name
        to: url
      }
    image {
      width
      gatsbyImageData(width: 1200, quality: 100, jpegProgressive: true)
      description
      title
    }
  }
`